<template>
    <article>
        <section class="cf cashlessProfile olcForm">
            <div v-if="!isUserLogged">
                <div class="module_callOut clear">
                    You'll receive recurring messages. Message &amp; data rates may apply. Reply STOP to cancel, HELP for help. Visit Mobile Terms and Privacy Policy visit: 
                    <a href="https://tnc.mobi/91011" target="_blank">https://tnc.mobi/91011</a>
                </div>
                <p>
                    Now you can use your mobile device to cash winning Ohio Lottery tickets between $50-$25,000!   It's a fast, secure and convenient way to get paid.  And it's built into the Ohio Lottery mobile app.  Simply scan an eligible ticket and select CASH OUT to begin the process.  Your winnings can be added into a bank account. It's that simple!
                </p>
                <p>
                    Once you set up your profile through the mobile app you will be able to manage your personal information and bank account here as well as on the mobile app.
                </p>
                <UserLoginVue contentKey="authButtons"></UserLoginVue>
            </div>
            <fieldset class="regForm cf" v-if="isUserLogged && cashlessUser">
                <div class="module_callOut">
                    <p class="InfoLabel activeSessionMsg" v-if="verified">Your session for making edits expires at: <span id="p_lt_WebPartZone3_zoneContent_pageplaceholder_p_lt_zoneContent_pageplaceholder_p_lt_zoneTop_CashlessPaymentInformation_CashlessSessionVerification_lblExpirationDate">{{ tokenTime }}</span><input type="hidden" name="p$lt$WebPartZone3$zoneContent$pageplaceholder$p$lt$zoneContent$pageplaceholder$p$lt$zoneTop$CashlessPaymentInformation$CashlessSessionVerification$hdnExpirationDate" id="p_lt_WebPartZone3_zoneContent_pageplaceholder_p_lt_zoneContent_pageplaceholder_p_lt_zoneTop_CashlessPaymentInformation_CashlessSessionVerification_hdnExpirationDate" value="5/3/2023 4:02:25 PM"></p>
                    <p class="alert-message MessageLabel" v-if="!verified">
                        If you wish to edit information on this page you must first validate your identity. Click 
                        <strong>Send Code</strong>
                         below and a verification code will be sent to your mobile phone. Enter the code in the field below and click 
                         <strong>Validate Code</strong>
                    </p>

                    <div class="userIDform dividerLine" v-if="!verified">
                        <button type="button" class="button btn_SendCode" @click="sendTwoFactor">SEND CODE</button>
                        <span class="tokenSent" v-if="tokenSubmitted">The Token was successfully Sent</span>
                        <br>
                        <label for="" class="lbl_txtCode">Verification Code </label>
                        <input type="text" class="txt_txtCode" v-model="verificationCode">
                        <button type="button" class="button btn_ValidateCode" @click="validateCode">VALIDATE CODE</button>
                    </div>

                </div>
            </fieldset>

            <fieldset class="regForm cf module_callOut" v-if="isUserLogged">
                <!-- User Information -->
                <h3>User Information</h3>
                <div class="dividerLine cf">
                    <div class="halfForm clear">
                        <span>First Name&nbsp;</span>
                        <span> <strong>{{ user.user.firstName }}</strong></span>
                        <!-- <input type="text" v-if="verified" v-model="firstName"> First Name
                        <span class="error" v-if="error && fristNameError">Please provide your first name.</span> -->
                    </div>
                    <div class="halfForm halfFormRight">
                        <span>Last Name&nbsp;</span>
                        <span> <strong>{{ user.user.lastName }}</strong></span>
                        <!-- <input type="text" v-if="verified" v-model="lastName">
                        <span class="error" v-if="error && lastNameError">Please provide your last name.</span> -->
                    </div>
                    <div class="halfForm clear">
                        <span class="socialSecurityWrapper toolTip">
                            Social Security Number
                            <span class="toolTipIcon toolTipDark" @mouseover="tooltip = true" @mouseleave="tooltip = false">?</span>
                            <span :class="['toolTipContent', {'show':tooltip}]">
                                Your social security number will be used to verify your identity.  The Lottery takes data security very seriously and all information provided will be treated as confidential information.
                            </span>
                        </span>
                        <!-- <input type="text" class="js_SSN" v-model="profile.ssn" v-if="vaidated"> -->
                        <span> <strong>&nbsp;{{ profile.ssn }}</strong></span>
                        <!-- <div class="fullForm clear formButtons" v-if="verified">
                            <button type="button" class="button btn_SubmitUserInfo testValidSubmit">SAVE</button>
                        </div> -->
                    </div>
                </div>
                <h3>Payment Information</h3>
                <!-- <div>
                    <table class="accountList accountList_CC">
                        <tbody>
                            <tr>
                                <td colspan="4">No Credit Card on record.</td>
                            </tr>
                        </tbody>
                    </table>
                </div> -->
                <div v-if="!bankUser && !bankOpen">
                    <table class="accountList accountList_Bank">
                        <tbody>
                            <tr>
                                <td colspan="4">No Bank Account on record.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- <table v-if="showBankInfo" class="accountList accountList_Bank"> -->
                <table v-if="showBankInfo" class="accountList accountList_Bank">
                    <tr>
                        <th class="colName">Bank&nbsp;Account</th>
                        <th class="colNumber">Account&nbsp;#</th>
                        <th class=""> </th>
                        <th class=""> </th>
                    </tr>
                    <tr v-for="(bankAccount, index) in bankAccounts" :key="index">
                        <td class="colName">{{ bankAccount.nickname }}</td>
                        <td class="colNumber">{{ bankAccount.accountNo }}</td>
                        <td class="colDefault">
                            <button class="button btn_MakeDefault" v-if="!bankAccount.isDefault && verified" @click="setDefaultPaymentMethod(bankAccount.userBankInfoID)">Make Default</button>
                            <span v-if="bankAccount.isDefault">Default</span>
                            <!-- make defaul span a star icon -->
                        </td>
                        <td class="colRemove" v-if="verified">
                            <button data-open="remove-modal" class="button btnRemoveConfirm">Remove Account</button>
                        </td>
                    </tr>
                </table>
                <!-- shows confirm dialogue below - as modal? -->
                <div id="remove-modal" data-reveal v-if="removing">
                    <i aria-hidden="true" class="icon-bin olcIcon"></i>
                    <p>Please confirm removal of the bank account ending in {{ bankAccount.accountNo }}</p>
                    <button class="button btn_MakeDefault" @click="removeBank(bankAccount.paysafeBankId)">Remove</button>
                    <button class="button btn-secondary" data-close>Cancel</button>
                </div>
                <!-- NOTE Credit Card Information -->
                <!-- <div class="accountForm_Card" v-if="verified">
                    <h4>Add Credit Card Information</h4>
                    <div class="halfForm clear">
                        <label for="">Card Nickname</label>
                        <input type="text">
                        <span class="error">Please provide a credit card nickname.</span>
                    </div>
                    <div class="halfForm halfFormRight">
                        <label for="">Card Number</label>
                        <input type="text" name="" value="" maxlength="19">
                        <span class="error">Please provide a valid credit card number.</span>
                    </div>
                    <div class="qtrForm clear">
                        <label for="">Expiration Month</label>
                        <input type="text" maxlength="2" placeholder="e.g. 03">
                        <span class="error">Please provide a valid month of expiration.</span>
                    </div>
                    <div class="qtrForm">
                        <label for="">Expiration Year</label>
                        <input type="text" maxlength="4" placeholder="e.g. 2019">
                        <span class="error">Please provide the year of expiration.</span>
                        <span class="error">Please provide a valid year of expiration.</span>
                    </div>
                    <div class="qtrForm qtrFormRight">
                        <label for="">CVV</label>
                        <input type="text" maxlength="4">
                        <span class="error">Please provide the CVV number.</span>
                        <span class="error">Please provide a valid CVV number.</span>
                    </div>
                    <h4 style="clear:both;">Billing Address</h4>
                </div> -->
                <!-- NOTE Bank Information -->
                <!-- <div class="accountForm_Bank" v-if="verified"> -->
                <div class="accountForm_Bank" v-if="bankOpen">
                    <h4>Add Bank Account Information</h4>
                    <div class="halfForm clear">
                        <label for="">Account Nickname</label>
                        <input name="" type="text" v-model="bankNickname">
                        <span class="error" v-if="errorNickname">Please provide the bank account nickname.</span>
                    </div>

                    <div class="halfForm halfFormRight">
                        <label for="">Routing Number</label>
                        <input name="" type="text" maxlength="9" class="js_BankRouting" v-model="bankRoutingNum">
                        <span class="error" v-if="errorRoutingNum">Please provide a bank routing number.</span>
                        <!-- <span class="error">Please provide a valid bank routing number.</span> -->
                    </div>

                    <div class="halfForm clear">
                        <label for="">Account Number</label>
                        <input name="" type="text" maxlength="20" class="js_BankAccount" v-model="bankAccountNum">
                        <span id="" class="error" v-if="errorAccountNumber">Please provide a bank account number.</span>
                        <!-- <span class="error">Please provide a valid bank account number.</span> -->
                    </div>
                    <div class="halfForm halfFormRight radioOptions">
                        <label>Bank Account Type</label>
                        <span class="radioButtonList">
                            <input type="radio" value="Checking" v-model="bankChecking">
                            <label for="">Checking</label>
                            <input type="radio" value="Savings" v-model="bankSavings">
                            <label for="">Savings</label></span>
                        <span class="error" v-if="errorType">Please select a bank account type.</span>
                    </div>
                    <h4 style="clear:both;">Address Associated to Account </h4>
                </div>
                <!-- NOTE Mailing Address -->
                <!-- <div class="accountForm_Address" v-if="verified"> -->
                <div class="accountForm_Address" v-if="bankOpen">
                    <div class="halfForm clear">
                        <label for="">Mailing Address</label>
                        <input type="text" maxlength="250" class="js_ccAddress1" v-model="bankAddress">
                        <span class="error" v-if="errorAddress">Please provide Mailing Address.</span>
                    </div>

                    <div class="halfForm halfFormRight">
                        <label for="p_lt_WebPartZone3_zoneContent_pageplaceholder_p_lt_zoneContent_pageplaceholder_p_lt_zoneTop_CashlessPaymentInformation_txtAddress2" id="p_lt_WebPartZone3_zoneContent_pageplaceholder_p_lt_zoneContent_pageplaceholder_p_lt_zoneTop_CashlessPaymentInformation_lblAddress2">Address (Continued)</label>
                        <input type="text" maxlength="350" class="js_ccAddress2" v-model="bankAddress2">
                    </div>

                    <div class="halfForm clear">
                        <label for="">City</label>
                        <input type="text" maxlength="250" class="js_ccCity" v-model="bankCity">
                        <span class="error" v-if="errorCity">Please provide a City.</span>
                    </div>

                    <div class="halfForm halfFormRight">
                        <label for="">State</label>
                        <input type="text" maxlength="2" class="js_ccState" v-model="bankState">
                        <span class="error" v-if="errorState">Please provide a State.</span>
                    </div>

                    <div class="qtrForm clear">
                        <label for="">Zip/Postal Code</label>
                        <input type="text" maxlength="100" class="js_ccZip" v-model="bankZip">
                        <spa class="error" v-if="errorZip">Please provide a Zip/Postal Code.</spa>
                    </div>
                </div>
                <!-- <div class="fullForm clear formButtons formButtons_Bank" v-if="verified"> -->
                <div class="fullForm clear formButtons formButtons_Bank" v-if="verified && bankOpen">
                    <button type="button" class="button testValidSubmit" @click="verifyBank">Submit Bank Account</button>
                    <a href="#" class="cancelAddBank" @click="bankOpen = false">Cancel</a>
                </div>
                <!-- <div class="fullForm clear formButtons formButtons_CC" v-if="verified">
                    <button class="button btn_SaveAddCC testValidSubmit">Submit Credit Card</button>
                    <a href="#btn_AddCC" class="cancelAddCC">Cancel</a>
                </div> -->
                <!-- <div class="cashlessButtons" v-if="verified"> -->
                <div class="cashlessButtons">
                    <!-- <button type="button" class="button btn_AddCC" id="btn_AddCC" v-if="verified && !creditUser && !creditCardOpen">Add A Credit Card</button> -->
                    <button type="button" class="button btn_AddBank" id="btn_AddBank" v-if="verified && !bankOpen" @click="bankOpen = true">Add A Bank Account</button>
                </div>
                <div style="font-size:1.2rem;line-height: 1.3em; padding-top: 0px;" class="module_callOut clear">
                    You'll receive recurring messages. Message &amp; data rates may apply. Reply STOP to cancel, HELP for help. Visit Mobile Terms and Privacy Policy visit: <a href="https://tnc.mobi/91011" target="_blank">https://tnc.mobi/91011</a>
                </div>
            </fieldset>
        </section>
    </article>
</template>

<script>
    import moment from "moment";
    import UserLoginVue from '../../ViewComponents/SignInRegister/SignInRegister.vue';
    import $ from 'jquery';
    import Foundation from "@/foundation-sites/js/foundation.js";

    export default {
        components: {
           UserLoginVue
        },
        data: function () {
            return {
                isUserLogged: this.isUserAuthed(),
                bankOpen: false,
                creditCardOpen: false,
                mobileCashing: false,
                token: '',
                user: '',
                profile: '',
                firstName: '',
                lastName: '',
                ssn: '',
                tokenSubmitted: false,
                error: false,
                errorCodeSubmit: false,
                verificationCode: '',
                verified: false,
                showBankInfo: false,
                bankUser: false,
                userBankInfoId: '',
                bankPaysafeId: '',
                bankPaysafeAddressID: '',
                bankNickname: '',
                bankRoutingNum: '',
                bankAccountNum: '',
                bankChecking: false,
                bankSavings: false,
                bankAddress: '',
                bankAddress2: '',
                bankCity: '',
                bankState: '',
                bankZip: '',
                creditUser: false,
                errorNickname: false,
                errorRoutingNum: false,
                errorAccountNumber: false,
                errorType: false,
                errorAddress: false,
                errorCity: false,
                errorState: false,
                errorZip: false,
                cashlessUser: false,
                bankAccounts: [],
                tokenTime: 0,
                removing: false,
                tooltip: false
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.user = this.$cookies.get('apiToken');
                    this.getBanks();
                    this.isCashless();
                    this.getProfile();
                });
            },
            isCashless() {
                let roles = this.user.user.listOfRoles;

                for (let i = 0; i < roles.length; i++) {
                    roles[i] === "CashlessUser" ? this.cashlessUser = true : false;
                }
            },
            getProfile() {
                let apiToken = this.token,
                userId = this.user.user.userId,
                url = process.env.VUE_APP_CASHLESSAPIURL + '/1.0/Cashless/User/GetProfile?userId=' + userId;

                fetch(url, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + apiToken,
                    },
                })
                .then((response) => response.json())
                .then((json) => {
                    if (json.error === null) {
                        let profile = json.data,
                        ssn = '*** **** ' + json.data.ssn;

                        profile.ssn = ssn;

                        this.profile = profile;
                    }
                });
            },
            sendTwoFactor() {
                let apiToken = this.token,
                userId = this.user.user.userId,
                url = process.env.VUE_APP_CASHLESSAPIURL + '/1.0/Cashless/User/GetVerificationCodeSessionSecondsLeft/' + userId;

                fetch(url, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + apiToken,
                    },
                })
                .then((response) => response.json())
                .then((json) => {
                    if (json.data === true) {
                        this.tokenSubmitted = true;
                    }
                });
            },
            validateCode() {
                let apiToken = this.token,
                    userId = this.user.user.userId,
                    url = process.env.VUE_APP_CASHLESSAPIURL + '/1.0/Cashless/User/VerifyCodeSessionSecondsLeft/' + userId + '/' + this.verificationCode;

                fetch(url, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + apiToken,
                    },
                })
                .then((response) => response.json())
                .then((json) => {
                    let d = json;

                    if (d.error === null) {
                        this.verified = true;
                        let timeRemaining = 20,
                            startDate = new Date();
                        this.tokenTime = moment(startDate).format('LL') + ' ' + moment(startDate, "MMMM Do YYYY, h:mm:ss a").add(timeRemaining, 'minutes').format('LTS');

                        // setInterval(() => {
                        //     if (timeRemaining > 0) {
                        //         timeRemaining--;
                        //     } else {
                        //         this.verified = false;
                        //     }
                        // }, 60000);
                    } else {
                        this.error = true;
                        this.errorCodeSubmit = true;
                    }
                });
            },
            // BANK METHODS
            getBanks() {
                let apiToken = this.token,
                    userId = this.user.user.userId,
                    url = process.env.VUE_APP_CASHLESSAPIURL + '/1.0/Cashless/Bank/GetBanks/' + userId;

                fetch(url, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + apiToken,
                    },
                })
                .then((response) => response.json())
                .then((json) => {
                    if (json.error) {
                        if (json.error.errorCode === 95) {
                            this.bankUser = false;
                            this.showBankInfo = false;
                        }
                    } else {
                        this.showBankInfo = true;
                        this.bankUser = true;
                        // test data copied from API
                        // this.bankAccounts = [
                        //     {
                        //     "userBankInfoID": 74163,
                        //     "userID": 69,
                        //     "paysafeBankID": "1b50a71d-f8e3-4ef3-b05e-d59de7121009",
                        //     "nickname": "chase: Checking",
                        //     "bankNo": "021000021",
                        //     "accountNo": "1234",
                        //     "billingZip": "44118",
                        //     "instructions": "",
                        //     "paysafeAddressID": "8026f2ba-3c13-4ce0-9865-e6139616e201",
                        //     "accountType": "Checking",
                        //     "isDefault": false,
                        //     "isError": false,
                        //     "errorCode": 0,
                        //     "errorMessage": null
                        //     }
                        // ];
                        this.bankAccounts = json.data;
                    }
                });
            },
            verifyBank() {
                this.bankNickname === '' ? this.errorNickname = true : this.errorNickname = false;
                this.bankRoutingNum === '' ? this.errorRoutingNum = true : this.errorRoutingNum = false;
                this.bankAccountNum === '' ? this.errorAccountNumber = true : this.errorAccountNumber = false;
                this.bankChecking === false && this.bankSavings === false ? this.errorType = true : this.errorType = false;
                this.bankAddress === '' ? this.errorAddress = true : this.errorAddress = false;
                this.bankCity === '' ? this.errorCity = true : this.errorCity = false;
                this.bankState === '' ? this.errorState = true : this.errorState = false;
                this.bankZip === '' ? this.errorZip = true : this.errorZip = false;
                // this.bankUser === false ? this.createBankInfo() : this.bankUpdate();
            },
            createBankInfo() {
                let apiToken = this.token,
                    url = process.env.VUE_APP_CASHLESSAPIURL + '/1.0/Cashless/Bank/CreateBankInfo',
                    body = {
                        "userId": this.user.userId,
                        "accountNickname": this.bankNickname,
                        "accountNo": this.bankAccountNum,
                        "routingNo": this.bankRoutingNum,
                        "address": this.bankAddress,
                        "address2": this.bankAddress2,
                        "city": this.bankCity,
                        "state": this.bankState,
                        "zip": this.bankZip,
                        "phone": '',
                        "accountType": this.bankChecking ? this.bankChecking : this.bankSavings
                    }

                fetch(url, {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + apiToken,
                    },
                })
                .then((response) => response.json())
                .then((json) => {
                    if (json.error === null) {
                        this.bankOpen = false;
                    }
                });
            },
            removeBank(id) {
                let apiToken = this.token,
                    url = process.env.VUE_APP_CASHLESSAPIURL + '/1.0/Cashless/Bank/DeleteBankInfo/' + id;

                fetch(url, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + apiToken,
                    },
                })
                .then((response) => response.json())
                .then((json) => {
                });
            },
            bankUpdate(id, name, add) {
                let apiToken = this.token,
                    url = process.env.VUE_APP_CASHLESSAPIURL + '/1.0/Cashless/Bank/UpdateBankInfo/' + id + '/' + name + '/' + add;

                fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + apiToken,
                    },
                })
                .then((response) => response.json())
                .then((json) => {
                });
            },
            setDefaultPaymentMethod(id) {
                let apiToken = this.token,
                    userId = this.user.user.userId,
                    url = process.env.VUE_APP_CASHLESSAPIURL + '/1.0/Cashless/Bank/SetDefaultPaymentMethod/' + userId + '/' + id;

                fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + apiToken,
                    },
                })
                .then((response) => response.json())
                .then((json) => {
                    this.getBanks();
                });
            }
		}
    };
</script>

<style lang="scss" scoped>
    .socialSecurityWrapper {
        position: relative;
    }
    .toolTipIcon {
        &:after {
            color: #424242;
        }
    }
    .toolTipContent {
        position: absolute;
        font-size: 1.2rem;
        left: 185px;
        background: #fff;
        display: none;

        &.show {
            display: block;
        }
    }
</style>