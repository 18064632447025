<template>
    <div>
        <UserLoginRegisterVue v-if="!isUserLogged" contentKey="authButtons"></UserLoginRegisterVue>
        <span v-if="isUserLogged && loaded">
            <div v-if="error" class="error">
                <div v-if="!passwordLowercase" class="passwordLowercase">- Passwords require at least one lower case letter.</div>
                <div v-if="!passwordUppercase" class="passwordUppercase">- Passwords require at least one upper case letter.</div>
                <div v-if="!passwordNumber" class="passwordNumber">- Passwords require at least one number.</div>
                <div v-if="!passwordLength" class="passwordLength">- Passwords must be a minimum of 10 characters.</div>
                <div v-if="passwordCharacter" class="passwordCharacter">- Passwords may not include spaces.</div>
                <div v-if="!passwordSpecial" class="passwordSpecial">- Passwords require at least one symbol.</div>
                <div v-if="!passwordMatch" class="passwordMatch">- New passwords do not match.</div>
            </div>
            <div v-if="passwordFail" class="callout alert">
                {{ passwordFailMessage }}
            </div>
            <div v-if="passwordUpdated" class="InfoLabel alert-success">
                Your password was successfully changed.
            </div>

            <form data-abide novalidate data-validate-on-blur="true" class="module module_callout passwordForm" v-show="statusCode">
                <fieldset id="customChangePassword" class="regForm cf grid-x grid-margin-x grid-margin-y">
                    <div class="cell medium-6">
                        <label for="passwordInput">Your existing password:</label>
                        <input type="password" name="passwordInput" class="oldPassword" v-model="oldPassword">
                    </div>
                    <div class="cell lblNote">
                        Please keep in mind that your password must be at least 10 characters long and contain the following: an
                                uppercase letter, a lowercase letter, a number and a symbol.
                    </div>
                    <div class="cell medium-6">
                        <label for="newPasswordInput">
                            New password:
                        </label>
                        <input type="password" name="newPasswordInput" maxlength="60" v-model="newPassword" @blur="validate" @keyup="keyUp('validate')" @keydown="keyDown">
                    </div>
                    <div class="cell medium-6">
                        <label for="confirmPasswordInput">
                            Confirm password:
                        </label>
                        <input type="password" name="confirmPasswordInput" maxlength="60" v-model="confirmPassword" @keyup="keyUp('match')" @keydown="keyDown">
                    </div>
                    <div class="cell">
                        <input :disabled="!valid" class="button btn-primary" value="Change Password" @click="submitPassword">
                    </div>
                </fieldset>
            </form>

        </span>
    </div>
</template>

<script>
    // import $ from 'jquery';
    import UserLoginRegisterVue from '../../ViewComponents/SignInRegister/SignInRegister.vue';

    export default {
        components: {
           UserLoginRegisterVue
        },
        data: function () {
            return {
                user: '',
                keyTimer: 0,
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
                passwordLowercase: true,
                passwordUppercase: true,
                passwordNumber: true,
                passwordLength: true,
                passwordCharacter: true,
                passwordSpecial: true,
                passwordMatch: true,
                error: false,
                valid: false,
                passwordUpdated: false,
                passwordFail: false,
                passwordFailMessage: '',
                isUserLogged: false,
                loaded: false,

                statusCode: true
            }
        },
        mounted: function () {
            this.getToken();
            this.isUserLogged = this.isUserAuthed();
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.user = this.$cookies.get('apiToken');
                    this.loaded = true;
                });
            },
            keyDown() {
                clearTimeout(this.keyTimer);
            },
            keyUp(method) {
                clearTimeout(this.keyTimer);
                if (method === 'match') {
                    if (this.confirmPassword != '') {
                        this.keyTimer = setTimeout(this.match, 400);
                    }
                }

                if (method === 'validate') {
                    this.keyTimer = setTimeout(this.validate, 400);
                }
            },
            validate() {
                this.passwordLowercase = true;
                this.passwordUppercase = true;
                this.passwordNumber = true;
                this.passwordLength = true;
                this.passwordCharacter = true;
                this.passwordSpecial = true;

                if (this.newPassword.length) {
                    var password = this.newPassword;
                    var pswdLength = password.length;

                    if (pswdLength < 10) {
                        this.passwordLength = false;
                    }

                    let pswdRegExA = /[A-Z]/g;
                    let pswdRegExa = /[a-z]/g;
                    let pswdRegExN = /\d/g;
                    let pswdRegExS = /\s/;
                    let pswdRegExSpecial = /[!@#$%^&*]/;

                    let pswdTestA = pswdRegExA.test(password);
                    let pswdTesta = pswdRegExa.test(password);
                    let pswdTestN = pswdRegExN.test(password);
                    let pswdTestS = pswdRegExS.test(password);
                    let pswdTestSpecial = pswdRegExSpecial.test(password);

                    if (pswdTestA == false) {
                        this.passwordUppercase = false;
                    }
                    if (pswdTesta == false) {
                        this.passwordLowercase = false;
                    }
                    if (pswdTestN == false) {
                        this.passwordNumber = false;
                    }
                    if (pswdTestS == false) {
                        this.passwordCharacter = false;
                    }
                    if (pswdTestSpecial == false) {
                        this.passwordSpecial = false;
                    }

                    if (this.confirmPassword != '') {
                        this.match();
                    }

                    this.handleError();
                }
            },
            match() {
                if (this.newPassword === this.confirmPassword) {
                    this.passwordMatch = true;
                } else {
                    this.passwordMatch = false;
                }

                this.handleError();
            },
            handleError() {
                if (
                    this.passwordLowercase === false ||
                    this.passwordUppercase === false ||
                    this.passwordNumber === false ||
                    this.passwordLength === false ||
                    this.passwordCharacter === true ||
                    this.passwordMatch === false ||
                    this.passwordSpecial === false
                ) {
                    this.error = true;
                } else {
                    this.error = false;
                }

                this.validatePassword();
            },
            validatePassword() {
				if (
                    this.error === false &&
                    this.passwordMatch === true
                ) {
                    this.valid = true;
                } else {
                    this.valid = false;
                }
            },
            submitPassword() {
                let apiToken = this.token,
                    urlProtocol = this.getProtocol(),
                    user = this.user.user.userId;

                let bodyAPI = {
                    "userId": user,
                    "oldPassword": this.oldPassword,
                    "newPassword": this.newPassword
                };

                return fetch(urlProtocol+process.env.VUE_APP_APIURL+'/1.0/PAM/CHangePassword', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer '+ apiToken,
                    },
                    body: JSON.stringify(bodyAPI)
                })
                .then(response => response.json())
                .then(json => {
                    if (json.data === true) {
                        this.passwordUpdated = true;
                        this.passwordFail = false;
                        this.statusCode = false;
                    } else {
                        this.passwordUpdates = false;
                        this.passwordFail = true;
                        this.passwordFailMessage = json.error.message;
                    }
                })
            }
		}
    };
</script>