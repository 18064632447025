import { render, staticRenderFns } from "./RetrievePassword.vue?vue&type=template&id=679fd6a1&scoped=true"
import script from "./RetrievePassword.vue?vue&type=script&lang=js"
export * from "./RetrievePassword.vue?vue&type=script&lang=js"
import style0 from "./RetrievePassword.vue?vue&type=style&index=0&id=679fd6a1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "679fd6a1",
  null
  
)

export default component.exports