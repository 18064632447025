<template>
    <div class="notification_setting_form">
        <div class="updating-checkboxes" v-if="isUpdatingCheckboxes">

            <!-- <hollow-dots-spinner
                :animation-duration="1000"
                :dot-size="15"
                :dots-num="3"
                color="#0FD8C9"
                v-if="isUpdatingCheckboxes"
            /> -->

        </div>


        <UserLoginVue v-if="!isUserLogged" contentKey="authButtons"></UserLoginVue>
        <NotificationSettings :dataUser="dataUser" :isUserLogged="isUserLogged" :loading="loading" :saved="saved" :fade="fade" @receive-offers="handleOffers"
            @notification-list="handleCheckbox" v-if="isUserLogged" />

        <!-- MODAL -->
        <div :class="{ 'reveal callout terms-modal': true, 'error-modal': modal.modalError }" id="modalNotifications"
            data-reveal>
            <div class="grid-x">
                <h5>{{ modal.title }}</h5>
                <div class="cell small-12">
                    <div v-html="modal.description"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import NotificationSettings from '../Account/NotificationSettings.vue';
import UserLoginVue from '../../ViewComponents/SignInRegister/SignInRegister.vue';
import { HollowDotsSpinner } from 'epic-spinners'

export default {
    components: {
        UserLoginVue,
        NotificationSettings,
        HollowDotsSpinner
    },
    data: function () {
        return {
            isUserLogged: false,
            isUpdatingCheckboxes: false,
            token: '',
            subscribeToOffers: false,
            dataUser: {
                userId: 0,
                notificationSettings: {}
            },
            modal: {
                title: '',
                description: '',
                modalError: false
            },
            saved: false,
            fade: false,
            loading: false
        }
    },
    mounted: function () {
        $(document).foundation();

        this.isUserLogged = this.isUserAuthed();
        this.isUserLogged && this.getToken();
    },
    methods: {
        getToken() {
            this.isUserLogged = this.isUserAuthed();
            this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    if (this.isUserLogged) {
                        this.getUser();
                    }
                });
        },
        getUser() {
            let _USER_APITOKEN = this.$cookies.get('apiToken');
            this.dataUser.userId = _USER_APITOKEN.user.userId;

            let url = process.env.VUE_APP_APIURL + '/1.0/PAM/GetUser/' + this.dataUser.userId, apiToken = this.token;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + apiToken
                }
            }).then((response) => response.json()).then((json) => {
                let data = json.data;
                
                if (data.notificationSettings != null) {
                    this.dataUser = {
                        userId: data.userId,
                        notificationSettings: {
                            cashPopWNEmail: data.notificationSettings.cashPopWNEmail,
                            cashPopWNPN: data.notificationSettings.cashPopWNPN,
                            powerJRollEmail: data.notificationSettings.powerJRollEmail,
                            powerJRollPN: data.notificationSettings.powerJRollPN,
                            powerWNEmail: data.notificationSettings.powerWNEmail,
                            powerWNPN: data.notificationSettings.powerWNPN,
                            power100JAEmail: data.notificationSettings.power100JAEmail,
                            power100JAPN: data.notificationSettings.power100JAPN,
                            power200JAEmail: data.notificationSettings.power200JAEmail,
                            power200JAPN: data.notificationSettings.power200JAPN,
                            power300JAEmail: data.notificationSettings.power300JAEmail,
                            power300JAPN: data.notificationSettings.power300JAPN,
                            power400JAEmail: data.notificationSettings.power400JAEmail,
                            power400JAPN: data.notificationSettings.power400JAPN,
                            megaJRollEmail: data.notificationSettings.megaJRollEmail,
                            megaJRollPN: data.notificationSettings.megaJRollPN,
                            megaWNEmail: data.notificationSettings.megaWNEmail,
                            megaWNPN: data.notificationSettings.megaWNPN,
                            mega100JAEmail: data.notificationSettings.mega100JAEmail,
                            mega100JAPN: data.notificationSettings.mega100JAPN,
                            mega200JAEmail: data.notificationSettings.mega200JAEmail,
                            mega200JAPN: data.notificationSettings.mega200JAPN,
                            mega300JAEmail: data.notificationSettings.mega300JAEmail,
                            mega300JAPN: data.notificationSettings.mega300JAPN,
                            mega400JAEmail: data.notificationSettings.mega400JAEmail,
                            mega400JAPN: data.notificationSettings.mega400JAPN,
                            lottoJRollEmail: data.notificationSettings.lottoJRollEmail,
                            lottoJRollPN: data.notificationSettings.lottoJRollPN,
                            lottoWNEmail: data.notificationSettings.lottoWNEmail,
                            lottoWNPN: data.notificationSettings.lottoWNPN,
                            lotto3JAEmail: data.notificationSettings.lotto3JAEmail,
                            lotto3JAPN: data.notificationSettings.lotto3JAPN,
                            lotto4JAEmail: data.notificationSettings.lotto4JAEmail,
                            lotto4JAPN: data.notificationSettings.lotto4JAPN,
                            lotto5JAEmail: data.notificationSettings.lotto5JAEmail,
                            lotto5JAPN: data.notificationSettings.lotto5JAPN,
                            lotto7JAEmail: data.notificationSettings.lotto7JAEmail,
                            lotto7JAPN: data.notificationSettings.lotto7JAPN,
                            hit5JRollEmail: data.notificationSettings.hit5JRollEmail,
                            hit5JRollPN: data.notificationSettings.hit5JRollPN,
                            hit5WNEmail: data.notificationSettings.hit5WNEmail,
                            hit5WNPN: data.notificationSettings.hit5WNPN,
                            hit5500JAEmail: data.notificationSettings.hit5500JAEmail,
                            hit5500JAPN: data.notificationSettings.hit5500JAPN,
                            match4WNEmail: data.notificationSettings.match4WNEmail,
                            match4WNPN: data.notificationSettings.match4WNPN,
                            pick3WNEmail: data.notificationSettings.pick3WNEmail,
                            pick3WNPN: data.notificationSettings.pick3WNPN,
                            dailyKenoWNEmail: data.notificationSettings.dailyKenoWNEmail,
                            dailyKenoWNPN: data.notificationSettings.dailyKenoWNPN,
                            allNewGamesEmail: data.notificationSettings.allNewGamesEmail,
                            allNewGamesPN: data.notificationSettings.allNewGamesPN,
                            newGames1Email: data.notificationSettings.newGames1Email,
                            newGames1PN: data.notificationSettings.newGames1PN,
                            newGames2Email: data.notificationSettings.newGames2Email,
                            newGames2PN: data.notificationSettings.newGames2PN,
                            newGames3Email: data.notificationSettings.newGames3Email,
                            newGames3PN: data.notificationSettings.newGames3PN,
                            newGames5Email: data.notificationSettings.newGames5Email,
                            newGames5PN: data.notificationSettings.newGames5PN,
                            newGames10Email: data.notificationSettings.newGames10Email,
                            newGames10PN: data.notificationSettings.newGames10PN,
                            newGames20Email: data.notificationSettings.newGames20Email,
                            newGames20PN: data.notificationSettings.newGames20PN,
                            newGames30Email: data.notificationSettings.newGames30Email,
                            newGames30PN: data.notificationSettings.newGames30PN,
                            subscribeToOffers: data.notificationSettings.subscribeToOffers
                        }
                    };
                }else{
                    this.dataUser = {
                        userId: data.userId,
                        notificationSettings: {}
                    };
                }
            });
        },
        UpdateNotifications() {
            //FORM
            this.loading = true;
            var apiToken = this.token
            let body = this.dataUser;

            this.isUpdatingCheckboxes = true;

            const methodHeaderData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + apiToken
                },
                body: JSON.stringify(body)
            };

            fetch("https:" + process.env.VUE_APP_APIURL + "/1.0/PAM/UpdateNotifications/", methodHeaderData)
                .then(response => response.json())
                .then(dataRegister => {
                    if (dataRegister.data == null) {

                        //MODAL
                        this.modal = {
                            title: "ERROR: updating your Profile",
                            description: dataRegister.error.message,
                            modalError: true
                        }
                        this.openModal('modalNotifications');

                        setTimeout(() => {
                            this.closeModal('modalNotifications');
                            this.loading = false;

                            
                            this.isUpdatingCheckboxes = false;
                        }, 7000);


                    } else {
                        // this.modal = {
                        //     title: "updating your Profile",
                        //     description: "Your changes have been saved.",
                        //     modalError: false
                        // }
                        // this.openModal('modalNotifications');

                        // setTimeout(() => {
                        //     this.closeModal('modalNotifications');
                        // }, 2000);
                        this.saved = true;

                        setTimeout(() => {
                            this.fade = true;
                        }, 500);

                        setTimeout(() => {
                            this.fade = false;
                            this.saved = false;
                            
                            this.isUpdatingCheckboxes = false;
                        }, 2000);

                    }
                });
        },
        toggleOptions(event) {
            const header = event.currentTarget;
            header.closest('.notifList').classList.toggle('toggled');
            header.closest('.notifList').querySelector('.nofitOptions').classList.toggle('toggled');

        },
        handleOffers(val) {
            this.subscribeToOffers = val;
            this.dataUser.notificationSettings.subscribeToOffers = this.subscribeToOffers;
            this.UpdateNotifications();
        },
        handleCheckbox(val) {
            const filteredArray = val.reduce((result, notificationGroup) => {
                notificationGroup.games.forEach(game => {
                    const typeAlert = game.typeAlert;
                    if (typeAlert) {
                        Object.assign(result, typeAlert);
                    }
                });

                return result;
            }, {});
            this.dataUser.notificationSettings = filteredArray;
            this.dataUser.notificationSettings.subscribeToOffers = this.subscribeToOffers;
            this.UpdateNotifications();
        },
        openModal(modalID) {
            $('#' + modalID).foundation('open');
        },
        closeModal(modalID) {
            $('#' + modalID).foundation('close');
        },
    }
};
</script>

<style lang="scss" scoped>
.notification_setting_form{
    position: relative;

    .updating-checkboxes{
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        background: white;
        z-index: 1;
        text-align: center;
        justify-content: center;
        align-items: center;
        opacity: 0.6;
    }
}



</style>