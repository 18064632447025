<template>
    <section class="tabbed_content fluid_tabs cf" id="TransactionLogPage">
        <UserLoginVue v-if="!isUserLogged" contentKey="authButtons"></UserLoginVue>
        <div v-if="isUserLogged">
            <div class="transactionFilter cf">
                <div>
                    <div class="date-fields">
                        <div class="fields-from">
                            <label for="txtDateFrom" class="date-label" id="txtDateFromLabel">
                                From
                                <input type="date" id="txtDateFrom" class="txtDateFrom date-archive picker__input"
                                    aria-haspopup="true" aria-readonly="false" aria-owns="txtDateFrom_root"
                                    @change="setStartDate" :value="startDate" :max="maxDate">
                            </label>

                        </div>
                        <div class="fields-to">
                            <label for="txtDateTo" class="date-label" id="txtDateToLabel">
                                To
                                <input type="date" id="txtDateTo" class="txtDateTo date-archive packer__input"
                                    aria-haspopup="true" aria-readonly="false" aria-owns="txtDateTo_root"
                                    @change="setEndDate" :value="endDate" :max="maxDate">
                            </label>

                        </div>
                        <button class="button btn_filterSubmit js_tLogFilter" @click="getTLog">Update Date Range</button>
                    </div>
                </div>
            </div>

            <label class="perPage" v-if="!isObjectEmpty(tLogItemsPagination)">
                <span>Items per page</span>
                <select v-model="itemsPerPage" @change="updateList">
                    <optgroup v-for="option in optionsPerPage" :key="option" style="padding:5px 0;">
                        <option :value="option">{{ option }}</option>
                    </optgroup>
                </select>
            </label>
            <label class="perPage" v-else>
                <span class="emptyResponse" v-if="!isLoading">There are no transactions to display.</span>
                <hollow-dots-spinner :animation-duration="1000" :dot-size="12" :dots-num="3" color="#0FD8C9" v-else />
            </label>

            <div id="transactionLog" class="transactionLog_MLR transactionLog cf"
                v-if="!isObjectEmpty(tLogItemsPagination)">
                <ul class="listHeader cf">
                    <li class="col1 listHead">
                        <span>Date</span>
                    </li>
                    <li class="col2 listHead">
                        <span v-if="section === 0">Achievement</span>
                        <span v-if="section === 1">Item</span>
                        <span v-if="section === 2">Ticket</span>
                    </li>
                    <li class="col3 listHead">
                        <span>Points</span>
                    </li>
                    <!-- <li class="col4 listHead" v-if="section === 1">
                        <span>Status</span>
                    </li> -->
                </ul>
                <ul class="transactionList">
                    <li v-for="(log, index) in tLogItemsPagination" :key="index" class="transLI cf TLogRow">
                        <div class="transDate" data-content="Date">
                            <span>{{ formatDate(log.operationDate) }}</span>
                        </div>
                        <div class="transType" data-content="Details">
                            <span v-if="section === 2" v-html="formatEntry(log.itemDescription)"></span>
                            <span v-else>{{ log.itemDescription }}</span>
                            
                        </div>
                        <div class="transAmount" data-content="Points">
                            <span v-if="log.itemAmount < 0">{{ addCommasToNumber(log.itemAmount * -1) }}</span>
                            <span v-if="log.itemAmount >= 0">{{ addCommasToNumber(log.itemAmount) }}</span>
                        </div>
                        <!-- <div class="transStatus" data-content="Status" v-if="section === 1">
                            <span>{{ log.itemStatus }}</span>
                        </div> -->
                    </li>
                </ul>
            </div>
            <div v-if="totalPages > 1" class="transactionLogPagination">
                <Pagination :totalPages="totalPages" :itemsPerPage="itemsPerPage" :currentPage="currentPage"
                    @changePage="changePage" :center="true" :right="true"></Pagination>
            </div>
        </div>
    </section>
</template>

<script>
import moment from "moment";
import Pagination from '../Pagination/pagination.vue';
import UserLoginVue from '../../ViewComponents/SignInRegister/SignInRegister.vue';
import Datepicker from "vue-moment-datepicker";
import { HollowDotsSpinner } from 'epic-spinners'
import $ from 'jquery';

export default {
    props: {
        section: {
            type: Number,
            default: null
        }
    },
    components: {
        Pagination,
        UserLoginVue,
        Datepicker,
        HollowDotsSpinner
    },
    data: function () {
        return {
            isUserLogged: false,
            token: '',
            dataUser: {
                userId: 0,
            },
            modal: {
                title: '',
                description: '',
                modalError: false
            },
            isLoading: false,
            optionsPerPage: [10, 20, 50, 100, 500, 1000],
            startDate: '',
            endDate: '',
            maxDate: '',
            getStartDate: '',
            getEndDate: '',
            disabledDates: {
                from: new Date()
            },
            tLogItems: {},
            tLogItemsPagination: {},
            totalPages: 0,
            itemsPerPage: 50,
            currentPage: 1,
            currentItems: '',
            testData: {
                "statusCode": 200,
                "message": "",
                "error": null,
                "data": {
                    "gameImages": {},
                    "tLogItems": [
                        {
                            "itemDescription": "PARTIALUSERCREDIT",
                            "itemAmount": 0,
                            "gameIdentifier": "",
                            "gameType": 0,
                            "serialNumber": "",
                            "operationDate": "2024-02-08T00:00:00"
                        },
                        {
                            "itemDescription": "TOTALUSERCREDIT",
                            "itemAmount": 0,
                            "gameIdentifier": "",
                            "gameType": 0,
                            "serialNumber": "",
                            "operationDate": "2024-02-07T00:00:00"
                        },
                        {
                            "itemDescription": "TOTALROWCOUNT",
                            "itemAmount": 0,
                            "gameIdentifier": "",
                            "gameType": 0,
                            "serialNumber": "",
                            "operationDate": "1753-01-02T00:00:00"
                        },
                        {
                            "itemDescription": "GAMEIDLIST",
                            "itemAmount": 0,
                            "gameIdentifier": "",
                            "gameType": 0,
                            "serialNumber": "",
                            "operationDate": "1753-01-01T00:00:00"
                        }
                    ]
                },
                "notificationList": []
            }
        }
    },
    mounted: function () {
        this.isUserLogged = this.isUserAuthed();
        this.isUserLogged && this.getToken();

        this.startDate = moment().startOf('month').format('yyyy-MM-DD');
        this.getStartDate = moment(this.startDate).format('l');
        this.endDate = moment(new Date()).format('yyyy-MM-DD');
        this.getEndDate = moment(this.endDate).format('l');

        this.maxDate = moment(new Date).toISOString().split("T")[0];

        $(document).ready(function () {
            function fixTabSeparatorHEader(el) {
                $('#menu-tabs li').removeClass('remove-sep');
                var index = el.index();
                if (index > 0) {
                    $('#menu-tabs li').eq(index - 1).addClass('remove-sep');
                }
            }

            $(window).on('load', function () {
                var elSelectedItem = $('#menu-tabs li.is-active');
                if (elSelectedItem.length > 0) {
                    fixTabSeparatorHEader(elSelectedItem);
                }
            });

            $('#menu-tabs li').on('click', function () {
                fixTabSeparatorHEader($(this));
            });
        });

    },
    methods: {
        getToken() {
            // this.isUserLogged = this.isUserAuthed();
            this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    if (this.isUserLogged) {
                        let _USER_APITOKEN = this.$cookies.get('apiToken');
                        this.dataUser.userId = _USER_APITOKEN.user.userId;
                        this.getTLog();
                    }
                });
        },
        getTLog() {
            this.tLogItemsPagination = {} //rest data to show Dots
            this.isLoading = true;
            let apiToken = this.token;

            let body = {
                "userId": this.dataUser.userId,
                // "userId": 2505574,
                "recordsPerPage": 10000,
                "startDate": new Date(this.startDate),
                "endDate": new Date(this.endDate),
                "pageNumber": 1,
                "section": this.section
            };

            const methodHeaderData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + apiToken
                },
                body: JSON.stringify(body)
            };

            fetch("https:" + process.env.VUE_APP_APIURL + "/1.0/LogTransaction/TransactionLog/GetTransactionLogRecords", methodHeaderData)
                .then(response => response.json())
                .then(data => {
                    if (!this.isObjectEmpty(data.data.transactionLogItems)) {
                        this.tLogItems = this.filteredTLogItems(data.data.transactionLogItems);
                        // this.tLogItemsPagination = this.tLogItems;
                        this.tLogItemsPagination = this.tLogItems;
                        this.updateList();
                        this.isLoading = false;
                    }
                });
        },
        isObjectEmpty(obj) {
            // check empty Object
            return Object.keys(obj).length === 0;
        },
        formatDate(date) {
            return moment(date).format('M/D/YY');
        },
        addCommasToNumber(number) {
            const parts = number.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join('.');
        },
        filteredTLogItems(json) {
            const excludedItemDescriptions = ["PARTIALUSERCREDIT", "TOTALUSERCREDIT", "TOTALROWCOUNT", "GAMEIDLIST"];
            return json.filter(item => !excludedItemDescriptions.includes(item.itemDescription));
        },
        setPages() {
            this.totalPages = Math.ceil(this.tLogItems.length / this.itemsPerPage);
            this.currentPage = 1;
        },
        changePage(p) {
            this.currentPage = p;
            this.setItems();
        },
        setItems() {
            let start = (this.itemsPerPage * this.currentPage) - this.itemsPerPage,
                end = (this.itemsPerPage * this.currentPage),
                currentItems = this.tLogItems.slice(start, end);
            this.tLogItemsPagination = this.filteredTLogItems(currentItems);
        },
        setStartDate(t) {
            let target = t;

            this.startDate = moment(target.target.value).format('yyyy-MM-DD');
            this.getStartDate = moment(target.target.value).format('l');
        },
        setEndDate(t) {
            let target = t;

            this.endDate = moment(target.target.value).format('yyyy-MM-DD');
            this.getEndDate = moment(target.target.value).format('l');
        },
        updateList() {
            this.setPages();
            this.setItems();
        },
        formatEntry(content) {
            let thisEntry = content;
            // let thiscontent = thisEntry.split(/[\r\n]+/);
            let splitAt = thisEntry.indexOf('\r\n');
            let firstPart = thisEntry.substring(0,splitAt);
            let secondPart = thisEntry.substring(splitAt+3);
            return '<span class="game-info">'+firstPart+'</span>'+secondPart;
        }
    }
};
</script>