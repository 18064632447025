<template>
    <section v-if="!loading">
        <a @click="backToList">Back to Items List</a>

        <div class="mlrUserOrderDetail">
            <h1>{{ product.orderItems[0].name }}</h1>
            <span class="purchasedOn">Purchased On: {{ product.orderDate }}</span>
            <!-- <span class="trackingNumber" v-if="physical">Tracking Number: {{ product.orderTrackingNumber === '' ? 'Not yet available' : product.orderTrackingNumber }}</span>
            <span class="lastUpdated" v-if="physical">Last Updated: {{ currentDate(product.orderLastModified) }}</span> -->

            <p>
                Order Number: {{ product.orderID }}
                <br>
                Status: {{ product.statusName }}
            </p>
            <img :src="cleanseImagePath(product.orderItems[0].imagePath)" alt="Gift Card Image" class="img-responsive">
            <div class="redemptionDetail" v-if="product.fulfillmentInfo">
                <p class="redDetailsTitle">Redemption Code:</p>
                <p class="redDetailsVal">{{ product.fulfillmentInfo.digitalFulfillmentInfo.giftCardCode }}</p>
            </div>
        </div>
        <div class="orderDetailBody">
            <p>{{ product.orderItems[0].name }}</p>
            <span v-html="product.orderItems[0].orderItemSKUDescription"></span>
        </div>
        <div class="orderDetailBtns">
            <button class="button btn-purple btn-secondary" @click="print">Print Order Details</button>
            <!-- <button class="button btn-purple btn-secondary" v-if="!physical" data-open="redeemOrderModal" @click="openRedeem(product)">Mark Redeemed</button> -->
        </div>

        <div id="redeemOrderModal" class="reveal reveal-modal redeemOrderModal data-modalOptions" data-reveal>
            <span v-if="!error">
                <p>Thanks for confirming that your Digital item is redeemed.</p>
                <div class="orderModalOptions">
                    <!-- <a class="button btn-purple btn-secondary" @click="closeRedeem">NO, KEEP ACTIVE</a> -->
                    <button type="button" class="button btn-primary redeemOrder" data-close @click="redirect">OKAY</button>
                </div>
            </span>
            <span v-if="error">
                <h3>Error</h3>
                <p>We're sorry there was an error with your transaction. Please try again later.</p>
                <button type="button" class="button btn-secondary redeemOrder" data-close @click="closeRedeem">CLOSE</button>
            </span>
        </div>
    </section>
</template>

<script>
    // import $ from 'jquery';
    import moment from "moment";
    import Foundation from "@/foundation-sites/js/foundation.js";

    export default {
        props:['orderId', 'toggleView', 'physical'],
        data: function () {
            return {
                mobileCashing: false,
                token: '',
                product: '',
                description: '',
                error: false,
                loading: true
            }
        },
        mounted: function () {
            this.getToken();
        },
        methods: {
            getToken() {
                this.getAPItoken()
                .then((token) => {
                    this.token = token;
                    this.user = this.$cookies.get('apiToken');
                    this.getOrder();
                });
            },
            redirect() {
                location.reload();
            },
            getOrder() {
                // console.log(this.orderId)

                let apiToken = this.token,
                    url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetUserOrderDetail?userId=' + this.user.user.userId + '&orderId=' + this.orderId;

                    // console.log(url)

                return fetch(url, {
                        method: 'GET',
                        // body: JSON.stringify(datCreds),
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    let orders = json.data;
                    // console.log(orders)

                    orders.orderDate = moment(new Date(orders.orderDate)).format('MM/DD/YYYY');

                    var domparser = new DOMParser();

                    let description = domparser.parseFromString(orders.orderItems[0].orderItemSKUDescription, 'text/html');
                    
                    this.description = description.body;

                    // console.log(this.description.body)

                    this.product = orders;

                    this.loading = false;

                     $(document).foundation();
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                    this.loading = false;
                });
            },
            print() {
                window.print();
            },
            openRedeem(product) {
                // console.log('Product')
                this.error = false;
                this.itemChosen = product;
                // console.log(this.itemChosen);
                let popup = new Foundation.Reveal($('#redeemOrderModal'));
                popup.open();

                this.redeemDigitalItem(true);
            },
            redeemDigitalItem(d) {
                let item = this.product,
                    apiToken = this.token;

                let url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/RedeemDigitalItem?userId=' + this.user.user.userId + '&productId=' + item.orderItems[0].skuid + '&orderId=' + item.orderItems[0].id + '&redeemed=' + d;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + apiToken
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    if (json.data === true) {
                        // this.getOrders();
                        this.closeRedeem();
                    } else {
                        this.error = true;
                    }
                })
           },
           closeRedeem() {
                this.enter = true;
                this.confirm = false;
                this.error = false;
                $('#redeemOrderModal').hide();
                $('.reveal-overlay').hide();
                let ele = document.documentElement;
                $(ele).removeClass('zf-has-scroll');
                $(ele).removeClass('is-reveal-open');
            },
            backToList(){
                this.$emit('toggleView');
            },
            currentDate(date) {
                let cDate = new Date();
                return moment(date).format('ddd, MMM DD, YYYY');
            },

		}
    };
</script>

<style scoped>
.mlrUserOrderDetail{
    margin-top: 25px;
    .purchasedOn,
    .trackingNumber,
    .lastUpdated{
        display: block;
        line-height: 2rem;
    }
    .lastUpdated{
        margin-bottom: 1rem;
    }
}
</style>