<template>
  <div class="achievements-wrapper">
    
    <div class="grid-x align-center loader-container" v-if="isLoading">
        <hollow-dots-spinner :animation-duration="1000" :dot-size="15" :dots-num="3" color="#6ec829" v-if="isLoading" />
    </div>

    <section class="achievements achievements-home" v-if="!isUserLogged && !showDetails && !isLoading">
      <h2>ACHIEVEMENTS</h2>
      <div class="grid-x small-12 medium-up-4" v-if="!showDetails && !isLoading">
        <div class="cell achievement" v-for="achievements in activeAchievements" :key="achievements.id">
          <div class="grid-x grid-margin-x">
            <div class="cell text-center">
              <img :src="achievements.imageURL" @error="imageLoadOnError($event, 150, 150)" @click="getDetails(userId, achievements.id)" />
            </div>

            <div class="cell text-center achievement-name">
              <h5><a @click="getDetails(userId, achievements.id)">{{achievements.name}}</a></h5>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section class="achievements" v-if="isUserLogged && !showDetails && !isLoading">
            <h2 v-if="!showDetails">ACHIEVEMENTS</h2>
            <div class="grid-x grid-margin-x small-12 medium-up-2" v-if="!showDetails">
                <div class="cell achievement small-12 medium-up-2" v-for="achievements in loggedAchievements" :key="achievements.id">
                    <div class="grid-x grid-margin-x">
                        <div class="large-6 cell">
                            <p><img :src="achievements.imageURL" @error="imageLoadOnError($event, 168, 168)" @click="getDetails(userId, achievements.id)"></p>
                        </div>
                        <div class="large-6 cell description">
                            <h5><a @click="getDetails(userId, achievements.id)">{{achievements.name}}</a></h5>
                            
                            <p>
                                You have earned this achievement
                                <strong v-if="achievements.repeatLimit === 0">{{ achievements.currentRepetition }} times</strong>
                                <strong v-else>{{ achievements.currentRepetition }} of {{ achievements.repeatLimit }} times</strong>
                            </p>

                            <div class="progress-items">

                                <div class="item" v-for="progressItem in achievements.progressItems">
                                    <progress v-if="!progressItem.isCompleted" :max="progressItem.maxValue" :value="progressItem.currentValue"></progress>
                                    <progress v-else class="completed" max="10" value="10"></progress>
                                    <div class="progress-inner-text" v-if="progressItem.isCompleted"> Complete </div>
                                    <div class="progress-inner-text" v-if="progressItem.isCumulative && !progressItem.isCompleted"> ${{progressItem.currentValue}} of ${{ progressItem.maxValue }} </div>
                                    <div class="progress-inner-text" v-if="!progressItem.isCumulative && !progressItem.isCompleted"> {{progressItem.currentValue}} of {{ progressItem.maxValue }} </div>
                                    <p v-if="achievements.progressItems.length > 1" class="itemName">{{ progressItem.itemName }}</p>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>


    </section>


    
    <AchievementsDetails v-if="showDetails" @toggleView="listView" :user-id="userId" :ach-id="achId"></AchievementsDetails>


  </div>

    
</template>

<script>
import { HollowDotsSpinner } from "epic-spinners";
import AchievementsDetails from "./AchievementsDetails/AchievementsDetail";

export default {
  components: {
    HollowDotsSpinner,
    AchievementsDetails,
  },
  data: function () {
    return {
      isLoading: true,
      isUserLogged: false,
      showDetails: false,
      detailId: "",
      activeAchievements: [],
      loggedAchievements: [],
      isCompleted: false,
      detail: false
    };
  },
  mounted: function () {
    this.getToken();
    this.isUserLogged = this.isUserAuthed();
  },
  
  methods: {
      getToken() {
          // then hit API with token
          this.getAPItoken().then((token) => {
              this.fetchData(token);
              this.token = token;
          });
      },
      fetchData(token) {
          let apiToken = token;
          
          this.user = this.$cookies.get('apiToken');

          if(this.isUserLogged){
              let loggedUrl = process.env.VUE_APP_ACHIEVEMENT + '/1.0/Achievements/GetAllUserAchievementProgress/' + this.user.user.userId;

              this.userId = this.user.user.userId;

              return fetch(loggedUrl, {
                      method: 'GET',
                      headers: {
                          'Content-Type': 'application/json',
                          'Authorization': "Bearer " + apiToken,
                      },
                  }
              )
              .then((response) => response.json())
              .then((json) => {
                  let data = json.data;
                  this.loggedAchievements = data;
                  this.isLoading = false;
              })
              .catch((error) => {
                  // console.error("There was an error!", error);
                  return (this.errorMessage = error);
              });

          }else{
              let url = process.env.VUE_APP_ACHIEVEMENT + '/1.0/Achievements/GetAllActiveAchievements';

              this.userId = this.user.user.userId;

              return fetch(url, {
                      method: 'GET',
                      headers: {
                          'Content-Type': 'application/json',
                          'Authorization': "Bearer " + apiToken,
                      },
                  }
              )
              .then((response) => response.json())
              .then((json) => {
                  let data = json.data;
                  this.activeAchievements = data;
                  this.isLoading = false;
              })
              .catch((error) => {
                  // console.error("There was an error!", error);
                  return (this.errorMessage = error);
              });
          }
      },
      getDetails(id, achId ){
          this.userId = id;
          this.achId = achId;
          this.showDetails = true;
      },
      imageLoadOnError(e, height, width) {
        e.target.src = "https://images.placeholders.dev/?width="+width+"&height="+height
      },
      listView(){
        this.showDetails = false;
      }
  },
  computed: {},
};
</script>

<style scoped>

.loader-container{
  margin-bottom: 20px;
}
.hollow-dots-spinner{
  margin: 20px;
}
</style>
