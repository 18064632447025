<template>
    <article class="drawingDetail">
        <UserMessageVue ref="user"></UserMessageVue>
        <!-- <SingInRegisterVue v-if="!isUserLogged"></SingInRegisterVue> -->
    <!-- <article class="drawingDetail"> -->
            <section v-if="loaded" class="callout thickPadding">
                <h1 class="productName">
                    {{ name }}
                </h1>
                    <section class="productImg cf callout light boxShadow">
                        <a class="enalrgeProductImg productDetailImg" title="Title Here" target="_blank" @click="openImage">
                            <img :src="image" alt="">
                        </a>
                    </section>
                    <section class="productOrderForm cf">
                        <div class="CartItemSelectorContainer">
                            <div class="TotalPriceContainer callout light">
                                <div class="TotalPriceQuantity" :class="{'notLogged': !isUserLogged}">
                                    <p v-if="isUserLogged">Qty</p>
                                    <select v-if="isUserLogged" name="quantity" class="input-select" id="" @change="handlePrice" :disabled="!pointsAvailable || stock === 0" v-model="qtySelected">
                                        <option v-if="!pointsAvailable" value="0">0</option>
                                        <option v-for="option in options" :key="option" :value="option">{{ option }}</option>
                                    </select>
                                    <div class="TotalPrice">{{ price.toLocaleString() }} points</div>
                                </div>
                            </div>
                            
                            <div class="AddToCartContainer add-to-card-container control-group-inline">
                                <p v-if="stock === 0 && isUserLogged">This product is currently out of stock.</p>
                                <p v-if="!pointsAvailable && isUserLogged" class="productDetail-points-error">You do not have enough points to complete this purchase</p>
                                <p v-if="!isUserLogged">Log in or create an account to purchase this item</p>
                                <button v-if="!isUserLogged" class="button btn-primary btn_popupLogIn" @click="login">Log In</button>
                                <a href="/register" v-if="!isUserLogged" class="button btn btn-secondary">Create Account</a>
                                <DetailDigital v-if="isUserLogged" @success="purchaseSuccess" :data="{
                                    isUserLogged: this.isUserLogged,
                                    userPoints: this.userPoints,
                                    pointsAvailable: this.pointsAvailable,
                                    category: this.category,
                                    description: this.description,
                                    image: this.image,
                                    name: this.name,
                                    parameter1: this.parameter1,
                                    parameter2: this.parameter2,
                                    parameter3: this.parameter3,
                                    parameter4: this.parameter4,
                                    parameter5: this.parameter5,
                                    qty: this.qtySelected,
                                    price: this.price,
                                    productId: this.productId,
                                    skuNumber: this.skuNumber,
                                    stock: this.stock,
                                    token: this.token,
                                    user: this.user,
                                    address: this.address
                                }"></DetailDigital>
                                
                                
                                <!-- <p class="notPointToChange" v-if="!pointsAvailable">You do not have enough points for this product.</p> -->
                            </div>
                        </div>
                    </section>
        
                    <section class="productInformation">
                        <h2>Product Information</h2>
                        <section class="productDescription" v-html="description"></section>
                        <div id="modal-image" class="reveal modal-image reveal-modal" data-reveal>
                            <section class="shoppingCartImage">
                                <picture class="productImg">
                                    <img :src="image" class="fullSizeImg">
                                </picture>
                            </section>
                        </div>
                    </section>
            </section>
    </article>
</template>

<script>
    import $ from 'jquery';
    import SignInRegisterVue from "../../ViewComponents/SignInRegister/SignInRegister.vue"
    import Foundation from "@/foundation-sites/js/foundation.js";
    import DetailDigital from './ProductDetailDigitalCatalog.vue';
    import Moment from 'moment';
    import UserMessageVue from '../Shared/User-Message.vue';

    export default {
        components:{
            DetailDigital,
            SignInRegisterVue,
            UserMessageVue
        },
        data: function () {
            return {
                id: 0,
                isUserLogged: false,
                userPoints: 0,
                baseCost: 0,
                pointsAvailable: false,
                category: '',
                description: '',
                image: '',
                name: '',
                parameter1: '',
                parameter2: '',
                parameter3: '',
                parameter4: '',
                parameter5: '',
                price: 0,
                productId: 0,
                skuNumber: '',
                stock: 0,
                token: {},
                user: {},
                address: '',
                purchase: false,
                message: '',
                loaded: false,
                options: '',
                qtySelected: 0
            }
        },
        mounted: function () {
            this.isUserLogged = this.isUserAuthed();            
            this.getToken();
        },
        methods: {
            purchaseSuccess() {
                this.$refs.user.getUser();
            },
            login() {
                $("#globalLogInModal").show();
                $("#globalLoginBlocker").show();
            },
            getToken() {
                // then hit API with token
                this.getAPItoken().then((token) => {
                    this.fetchData(token);
                    this.token = token;
                });
            },
            fetchData(token) {
                let idTest = document.getElementById("id").value;

                this.id = idTest;
                
                var apiToken = token,
                    url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/GetProductDetail?productId=' + this.id;

                return fetch(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    let data = json.data;

                    //NOTE This is assuming a ~ is in poition 0
                    let image = data.image,
                        imageSplit = image.split("~");
                    
                    this.category = data.category;
                    this.description = data.description;
                    this.image = data.image;
                    this.name = data.name;
                    this.parameter1 = data.parameter1;
                    this.parameter2 = data.parameter2;
                    this.parameter3 = new Moment(data.parameter3).format("MM/DD/YYYY");
                    this.parameter4 = new Moment(data.parameter4).format("MM/DD/YYYY");
                    this.parameter5 = data.parameter5;
                    this.price = data.price;
                    this.productId = data.productId;
                    this.skuNumber = data.skuNumber;
                    this.stock = data.stock;

                    if (this.category === 'Weekly' || this.category === 'Monthly' || this.category === 'Yearly') {
                        this.drawing = true;
                    } else if (this.category === '') {
                        this.coupon = true;
                    } else {
                        this.catalog = true;
                    }

                    let points = 0;

                    if (this.isUserLogged) {
                        this.getUserPoints();
                        this.user = this.$cookies.get('apiToken');

                        points = this.user.user.pointBalance.balance;
                        // Check to be sure this works?
                        this.address = this.user.user.address;
                        this.userPoints = points;
                    }

                    this.baseCost = data.price;

                    this.userPoints > data.price ? this.pointsAvailable = true : this.pointsAvailable = false;
                    this.qtyOptions = Math.floor(this.userPoints / data.price);
                    this.setSelect();
                    this.loaded = true;
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
            },
            setSelect() {
                let optionsArray = [];

                for (let i = 0; i < this.qtyOptions; i++) {
                    let amount = i + 1;
                    
                    optionsArray.push(amount);

                    if ( amount > this.stock) {
                        break;
                    }

                    if ( i === 9) {
                        break;
                    }
                }

                this.options = optionsArray;

                if (optionsArray.length) {
                    this.qtySelected = optionsArray[0];
                }
            },
            handlePrice(d) {
                this.qtySelected = d.target.value;
                this.price = this.baseCost * d.target.value;
            },
           openPurchase() {
            let popup = new Foundation.Reveal($('#modal-checkout'));
            popup.open();
           },
           openImage() {
            let popup = new Foundation.Reveal($('#modal-image'));
            popup.open();
           },
           confirmPurchase() {
            let url = process.env.VUE_APP_APIURL + '/1.0/Ecommerce/PerformPurchase',
                body = {
                    "userId": this.user.user.userId,
                    "productId": this.productId,
                    "qty": this.qtySelected
                };
            
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json-patch+json',
                    'Authorization': "Bearer " + this.token
                },
            })
            .then((response) => response.json())
            .then((json) => {
                let res = json;

                if (res.data === null && res.error != null) {
                    this.message = res.error.message;
                    this.purchase = true;
                } else {
                    this.message = "Thank you for your purchase";
                    this.purchase = true;
                    this.updatePoints = true;
                    setTimeout(() => {
                        this.upatePoints = false;
                    }, 1000);
                }
            })
           }
        },
        computed: {
        }
    };
</script>

<style lang="scss" scoped>

</style>